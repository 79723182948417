exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-projekte-index-js": () => import("./../../../src/pages/projekte/index.js" /* webpackChunkName: "component---src-pages-projekte-index-js" */),
  "component---src-pages-referenzen-index-js": () => import("./../../../src/pages/referenzen/index.js" /* webpackChunkName: "component---src-pages-referenzen-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-datenschutz-js": () => import("./../../../src/templates/datenschutz.js" /* webpackChunkName: "component---src-templates-datenschutz-js" */),
  "component---src-templates-impressum-page-js": () => import("./../../../src/templates/impressum-page.js" /* webpackChunkName: "component---src-templates-impressum-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-partner-page-js": () => import("./../../../src/templates/partner-page.js" /* webpackChunkName: "component---src-templates-partner-page-js" */),
  "component---src-templates-project-page-js": () => import("./../../../src/templates/project-page.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-reference-page-js": () => import("./../../../src/templates/reference-page.js" /* webpackChunkName: "component---src-templates-reference-page-js" */)
}

